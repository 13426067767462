
import { store } from '@/assets/scripts/store'
import { bestPicFormat } from '@/assets/scripts/utils'

export default {
  name: 'ApiContent',

  props: {
    apiData: { type: Object, default: () => ({ error: null, content: null }) },
  },

  computed: {
    screenLarge() {
      return store.screenLarge
    },
  },

  created() {
    this.bestPicFormat = bestPicFormat
  },

  methods: {
    needWhiteText(color) {
      // https://stackoverflow.com/a/12043228
      const c = color.substring(1)
      const rgb = parseInt(c, 16)
      const r = (rgb >> 16) & 0xff
      const g = (rgb >> 8) & 0xff
      const b = (rgb >> 0) & 0xff
      const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b
      return luma < 200
    },
  },
}
