
export default {
  props: {
    seasons: { type: Object, default: () => { } },
  },

  data() {
    return {
      result: 0,
      isSuccess: false,
      seasonID: null,
    }
  },

  computed: {
    overlimit() {
      return this.result > 99
    }
  },

  methods: {
    generate() {
      this.result = 'X'

      if (!this.seasonID) return

      const guestCount = this.$refs.inputGuest && this.$refs.inputGuest.$refs.input ? this.$refs.inputGuest.$refs.input.value : null
      if (!guestCount || guestCount < 1) return

      const ratio = this.seasons && this.seasons[this.seasonID] ? this.seasons[this.seasonID].ratio : 0
      if (!ratio) return 0

      this.result = Math.ceil(guestCount * ratio)
      this.isSuccess = true
    }
  }
};
