
import { bestPicFormat } from '@/assets/scripts/utils'

export default {
  name: 'ApiEnum',
  props: {
    apiData: { type: Object, default: () => {} },
  },
  created() {
    this.bestPicFormat = bestPicFormat
  },
}
