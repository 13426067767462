
import BasicCalculator from './Calculator/BasicCalculator'

export default {
  components: { BasicCalculator },

  props: {
    seasons: { type: Object, default: () => {} }
  }
};
