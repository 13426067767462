import { render, staticRenderFns } from "./BasicCalculator.vue?vue&type=template&id=6272567c&"
import script from "./BasicCalculator.vue?vue&type=script&lang=js&"
export * from "./BasicCalculator.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputSelect: require('/opt/build/repo/components/InputComponents/InputSelect.vue').default,InputText: require('/opt/build/repo/components/InputComponents/InputText.vue').default})
